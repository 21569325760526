@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes bounce {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
}

.bounce {
  animation: bounce .75s infinite;
}

/* Add this to your CSS file */
.slider {
  -webkit-appearance: none;
  /* Remove default styling */
  height: 4px;
  /* Height of the track */
  background: #000;
  /* Default track color */
  border-radius: 2px;
  /* Rounded corners */
  outline: none;
  /* Remove outline */
}

/* Style for the slider thumb */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* Remove default styling */
  appearance: none;
  /* Remove default styling */
  width: 20px;
  /* Width of the thumb */
  height: 20px;
  /* Height of the thumb */
  background: #4A90E2;
  /* Color of the thumb */
  border-radius: 10%;
  /* Rounded thumb */
  cursor: pointer;
  /* Pointer cursor on hover */
  margin-top: -8px;
  /* Center the thumb vertically */
}

.slider::-moz-range-thumb {
  width: 5px;
  /* Width of the thumb */
  height: 20px;
  /* Height of the thumb */
  background: #EF4444;
  /* Color of the thumb */
  border-radius: 10%;
  /* Rounded thumb */
  cursor: pointer;
  /* Pointer cursor on hover */
}

/* Style for the track in WebKit browsers */
.slider::-webkit-slider-runnable-track {
  background: #000;
  /* Track color */
}

/* Style for the track in Firefox */
.slider::-moz-range-track {
  background: #000;
  /* Track color */
}